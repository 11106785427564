// src/data/AdData.js

export class AdData {
	constructor(title, profession, startAmount, endAmount, description, imageAd, imageStats) {
	this.title = title;
	this.profession = profession;
	this.startAmount = startAmount;
	this.endAmount = endAmount;
	this.description = description;
	this.imageAd = imageAd;
	this.imageStats = imageStats;
	}
}

export class PageData {
	constructor(title, profession, link, description, imagePage) {
	this.title = title;
	this.profession = profession;
	this.description = description;
	this.link = link;
	this.imagePage = imagePage;
	}
}
