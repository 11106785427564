<template>
    <div id="main">
        <div id="part1" class="part">
            <h1>Szybszy Wzrost. Więcej Klientów.</h1>
            <h1 class="bigger"><a class="gold">Gwarantowane</a>.</h1>
        </div>
        <div id="part2" class="part">
            <a class="button" @click="scrollToForm">TAK, CHCĘ TEGO!</a>
        </div>
        <div id="part3" class="part">
            <h1>Marketing jest ważny...</h1>
            <h2>...ale na Twojej liście rzeczy do zrobienia jest już <a class="gold">101</a> innych zadań. <br>I one też są ważne!</h2>
            <h2>Jak więc zoptymalizować swój marketing?</h2>
        </div>
        <div id="part4" class="part">
            <div id="dismiss1">
                <img class="ludzik" src="../assets/ludzik1.png"/>
                <h3>Zajmować się wszystkim samemu?</h3>
                <a>To dobre rozwiązanie, jeśli masz czas na kolejną pracę na pełen etat. <br><br>Jeśli jednak masz już dużo na głowie… to nie jest zbyt dobra opcja.</a>
            </div>
            <div id="dissmiss2">
                <img class="ludzik" src="../assets/ludzik2.png"/>
                <h3>Nowi pracownicy?</h3>
                <a>Znalezienie dobrych ludzi nie jest łatwe. <br>Szkolenie i wdrażanie pracowników jest kosztowne. <br><br>A jeśli nawet uda Ci się znaleźć idealnego pracownika na to stanowisko - nadal jesteś zależny od jednej osoby!</a>
            </div>
            <div id="dissmiss3">
                <img class="ludzik" src="../assets/ludzik3.png"/>
                <h3>Zatrudnić agencję?</h3>
                <a>Nie masz budżetu na marketing w wysokości dziesiątek tysięcy złotych? <br><br>W takim razie możesz skończyć ze stroną stworzoną przez stażystę asystenta z pobliskiej zawodówki. To raczej nie jest tego warte.</a>
            </div>
        </div>
        <div id="part5" class="part">
            <h2>„Ok… Więc co sprawia, że aż tak się różnicie?”</h2>
            <div id="guarantees">
                <div id="guarantee1">
                    <h3>Gwarancja</h3>
                    <a>Wygrywamy tylko wtedy, gdy Ty wygrasz. To podstawa dobrej współpracy. <br><br>Nie ponosisz całego ryzyka, dzielimy się nim.</a>
                </div>
                <div id="guarantee2">
                    <h3>Lokalnie</h3>
                    <a>Nie jesteśmy ukryci w jakimś anonimowym call center 500 kilometrów od Ciebie. <br><br>Jesteśmy lokalną firmą, więc możesz się z nami skontaktować, kiedy będziesz nas potrzebować.</a>
                </div>
                <div id="guarantee3">
                    <h3>Wyniki</h3>
                    <a>Naszym priorytetem są najlepsze wyniki. <br><br>Albo tworzymy dla Ciebie reklamy lepsze, niż masz dotychczas, albo nam nic nie płacisz.</a>
                </div>
                <div id="guarantee4">
                    <h3>Specjalizacja</h3>
                    <a>Współpracujemy tylko z branżami, które dobrze znamy. <br><br>Dzięki temu możemy zagwarantować najlepsze wyniki.</a>
                </div>
            </div>
        </div>
        <div id="part5-5" class="part">
            <h1>Nasze projekty</h1>
            <h2>Chcesz mieć pewność, że znamy się na <a class="gold">marketingu</a>? <br>Zobacz reklamy i strony internetowe firm, dla których pracowaliśmy!</h2>
            <router-link to="/portfolio" class="button">Zobacz portfolio</router-link>
        </div>
        <div id="part6" class="part">
            <h2>Ty zajmiesz się tym, w czym jesteś <a class="gold">najlepszy</a>. <br>My zajmiemy się znalezieniem dla Ciebie <a class="gold">klientów</a>.</h2>
        </div>
        <div id="part7" class="part">
            <h3>Chcesz wiedzieć, co możemy dla Ciebie zrobić? Wypełnij formularz, a my skontaktujemy się z Tobą.<br>Bez zobowiązań, bez irytującej sprzedaży pod wysokim ciśnieniem. Nie będziemy marnować Twojego czasu.</h3>
            <div id="form">
                <input type="text" class="form50" placeholder="Imię" v-model="name"/>
                <input type="email" class="form50" placeholder="E-Mail" v-model="email"/>
                <input type="text" class="form50" placeholder="Firma" v-model="company"/>
                <input type="text" class="form50" placeholder="Telefon"  v-model="phone"/>
                <input type="text" class="form100" placeholder="Jakie jest Twoje najważniejsze pytanie?" v-model="question"/>
                <textarea placeholder="Wiadomość"  v-model="message"></textarea>
                <a class="button" @click="sendInfo">WYŚLIJ</a>
            </div>
            <a ref="warningRef">{{ warningMessage }}</a>
        </div>
    </div>
</template>
<script>
import { inject, ref, reactive, toRefs } from 'vue'
import axios from 'axios';

export default {
    setup() {
        document.title = "RMD Solutions - Marketing"
        const endpoint = inject("g_endpoint");

        const formData = reactive({
            name: "",
            email: "",
            company: "",
            phone: "",
            question: "",
            message: ""
        });

        const warningMessage = ref("");

        const warningRef = ref(null);

        const validateField = (condition, message) => {
            if (!condition) {
                warningMessage.value = message;
                if (warningRef.value) {
                    warningRef.value.style.visibility = "visible";
                }
                return false;
            }
            return true;
        };


        const sendInfo = async () => {
            if (!validateField(formData.name !== '', 'Podaj imię.')) return;
            if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), 'Podaj poprawny adres email.')) return;
            if (!validateField(formData.company !== '', 'Podaj nazwę firmy.')) return;
            if (!validateField(/^\d{9}$/.test(formData.phone), 'Numer telefonu powinien składać się z 9 cyfr.')) return;
            if (!validateField(formData.question !== '' || formData.message !== '', 'Pytanie i wiadomość nie mogą być puste.')) return;

            try {
                let dataToSend = {
                    name: formData.name,
                    email: formData.email,
                    company: formData.company,
                    phone_number: formData.phone,
                    main_question: formData.question,
                    message: formData.message
                }
                const jsonFormData = JSON.stringify(dataToSend);

                const response = await axios
                .post(`${endpoint}messages/`, jsonFormData, {
                withCredentials: true,
                headers: {'Content-Type': "application/json"}})

                Object.keys(formData).forEach(key => formData[key] = '');
                warningMessage.value = "Wiadomość wysłana poprawnie";
            } catch (error) {
                console.error("Błąd podczas wysyłania danych:", error);
                warningMessage.value = "Wiadomość nie została wysłana";
            }
        };


        const scrollToForm = () => {
            const formElement = document.getElementById("form");
            if (formElement) {
                formElement.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return { ...toRefs(formData), warningMessage, sendInfo, scrollToForm, warningRef }
    }
}
</script>
<style lang="scss" scoped>
@import '../style/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        .part
        {
            width: 100%;
            padding-top: 5%;
            padding-bottom: 5%;
        }

        a.gold
        {
            color: $gold-color;
        }

        #part1
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1
            {
                text-align: center;
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
            }

            .bigger
            {
                font-size: 3.1rem;
            }
        }

        #part2
        {
            display: flex;
            align-items: center;
            justify-content: center;

        }

        #part3
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1
            {
                text-align: center;
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
            }

            h2
            {
                text-align: center;
            }
        }

        #part4
        {
            display: flex;
            flex-direction: column;
            justify-content: center;

            div
            {
                width: 100%;
                margin-left: 2%;
                margin-right: 2%;
                text-align: center;
                margin-bottom: 20%;

                h3
                {
                    text-align: center;
                    font-size: 1.3rem;
                    line-height: 2rem;
                }

                a
                {
                    line-height: 2rem;
                }
            }

            .ludzik
            {
                height: 100px;
            }
        }

        #part5
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            h2
            {
                font-size: 2.5rem;
                width: 90%;
                text-align: center;
            }

            h3
            {
                font-size: 1.3rem;
            }

            #guarantees
            {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;

                div
                {
                    width: 100%;
                    margin-bottom: 10%;
                    text-align: center;
                    border: 1px solid $gold-color;
                    padding: 2%;

                    h3
                    {
                        text-align: center;
                        margin-top: 0;
                    }

                    a
                    {
                        line-height: 2rem;
                    }
                }
            }
        }

        #part5-5
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1
            {
                text-align: center;
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
            }

            h2
            {
                font-size: 1.5rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }

            #portfolio
            {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 90%;

                div
                {
                    width: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-bottom: 25%;
                    cursor: pointer;
                }

                .link
                {
                    width: 100%;
                    height: 100%;
                    z-index: 10000;
                }

                #portfolio1
                {
                    background-image: url('../assets/portfolio-gigakorki.png');
                    padding-top: 3720/1417 * 100%;
                }
                #portfolio2
                {
                    background-image: url('../assets/portfolio-pay24hrs.png');
                    padding-top: 3803/1417 * 100%;
                }
                /* #portfolio3
                {
                    background-image: url('../assets/logo.png');
                    padding-top: 294/597 * 100%;
                } */
            }
        }

        #part6
        {
            display: flex;
            justify-content: center;

            h2
            {
                font-size: 1.5rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }
        }

        #part7
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;

            h3
            {
                font-size: 1.2rem;
                text-align: center;
                width: 90%;
                line-height: 2.5rem;
            }

            #warning
            {
                margin-top: 1%;
                visibility: hidden;
            }

            #form
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 90%;


                input
                {
                    padding: 1%;
                    padding-left: 2%;
                    margin: 0;
                }

                textarea
                {
                    padding: 1%;
                    padding-left: 2%;
                    margin: 0;
                    width: 100%;
                    resize: none;
                    height: 10rem;
                    margin-top: 1%;
                }

                .form50
                {
                    width: 100%;
                    margin-top: 1%;
                    margin-bottom: 1%;
                }

                .form100
                {
                    width: 100%;
                    margin-top: 1%;
                    margin-bottom: 1%;
                }

                a
                {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        input, textarea
        {
            outline: none;
            background-color: $main-color;
            border: none;
            border-bottom: 1px solid white;
            color: white;
            font-size: 1rem;
        }

        .button
        {
            height: 3rem;
            font-size: 2rem;
            padding: 10%;
            text-transform: uppercase;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5%;

            white-space: nowrap;
            transition: 0.2s ease-in-out;
            border: 1px solid $gold-color;
            border-radius: 75px;
            text-decoration: none;
            color: $font-color;
            background-color: $gold-color;
            font-weight: 200;
            cursor: pointer;

            &:hover
            {
                background-color: $font-color;
                border: 1px solid $font-color;
                color: $gold-color;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #main
        {
            .part
            {
                padding-top: 2%;
                padding-bottom: 2%;
            }

            #part1
            {
                h1
                {
                    font-size: 4rem;
                }

                .bigger
                {
                    font-size: 6rem;
                }
            }

            #part3
            {
                h1
                {
                    font-size: 4rem;
                }
            }

            #part4
            {
                flex-direction: row;

                div
                {
                    width: 20%;
                    margin-bottom: 0;
                }
            }

            #part5
            {
                h2
                {
                    font-size: 3rem;
                    width: 75%;
                }

                #guarantees
                {
                    flex-direction: row;

                    div
                    {
                        width: 30%;
                        margin: 2%;
                    }
                }
            }

            #part5-5
            {
                h1
                {
                    font-size: 4rem;
                }

                h2
                {
                    font-size: 2rem;
                    line-height: 4rem;
                }

                #portfolio
                {
                    flex-direction: row;

                    div
                    {
                        width: 30%;
                        margin-bottom: 0;
                    }

                    #portfolio1
                    {
                        padding-top: 3720/1417/3 * 100%;
                    }
                    #portfolio2
                    {
                        padding-top: 3803/1417/3 * 100%;
                    }
                    #portfolio3
                    {
                        padding-top: 294/597/3 * 100%;
                    }
                }
            }

            #part6
            {
                h2
                {
                    font-size: 3rem;
                    line-height: 4rem;
                    width: 75%;
                }
            }

            #part7
            {
                h3
                {
                    width: 75%;
                }

                #form
                {
                    width: 75%;

                    .form50
                    {
                        width: 47%;
                    }
                }
            }

            input, textarea
            {
                font-size: 1.5rem;
            }

            .button
            {
                font-size: 2.5rem;
                padding: 3%;

                margin-top: 2%;
            }
        }
    }
</style>