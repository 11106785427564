<template>
    <div id="main">
        <h2>Kontakt</h2>
        <h3>Napisz do nas na adres email:</h3>
        <h2 id="mail">rafal@rmdsolutions.eu</h2>
        <h3>Odpiszemy Ci w ciągu 24 godzin.</h3>
    </div>
</template>
<script>
    export default
    {
        setup()
        {
            document.title = "Skontaktuj się z nami"
        }
    }
</script>
<style lang="scss" scoped>
@import '../style/style.scss';
    #main
    {
        margin-bottom: 20%;

        width: 100%;
        //margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2
        {
            width: 100%;
            text-align: center;
            font-size: 4rem;
            font-weight: 300;
            color: $gold-color;
        }

        h3
        {
            width: 80%;
            text-align: center;
            font-size: 2rem;
            font-weight: 300;
        }

        a
        {
            width: 80%;
            font-size: 1.2rem;
            line-height: 2rem;
            text-align: center;
            font-weight: 200;
        }

        #mail
        {
            user-select: all;
        }
    }
    @media only screen and (max-width: 600px)
    {
        #main
        {
            h2
            {
                font-size: 2.2rem;
            }

            h3
            {
                font-size: 1.5rem;
            }

            a
            {
                font-size: 1rem;
            }
        }
    }
</style>