<template>
    <div id="baner">
        <router-link to="/" id="logo"><img src="../assets/logo.png"/></router-link>
        <div id="links">
            <router-link to="/analiza">Darmowa Analiza Marketingowa</router-link>
            <router-link to="/poradnik">Darmowy Poradnik</router-link>
            <router-link to="/blog">Blog</router-link>
            <img src="../assets/burger.png" id="menu" @click="showMenu"/>
        </div>
        <div id="fullScreen">
            <router-link to="/analiza">Darmowa Analiza Marketingowa</router-link>
            <router-link to="/poradnik">Darmowy Poradnik</router-link>
            <router-link to="/blog">Blog</router-link>
            <router-link to="/oferta">Co Możemy Dla Ciebie Zrobić</router-link>
            <!-- <router-link to="/oNas">O Nas</router-link> -->
            <router-link to="/portfolio">Portfolio</router-link>
            <!-- <router-link to="/opinie">Opinie</router-link> -->
            <router-link to="/kontakt">Kontakt</router-link>
            <img src="../assets/burger.png" id="closeMenu" @click="hideMenu"/>
        </div>
        <img src="../assets/burger.png" id="menuOnly" @click="showMenu"/>
    </div>
</template>
<script>
import { watch } from 'vue';
import { useRoute } from 'vue-router';
    export default
    {
        setup()
        {
            const route = useRoute();

            watch(route, () =>
            {
                hideMenu();
            });

            function disableScrolling()
            {
                var x=window.scrollX;
                var y=window.scrollY;
                window.onscroll=()=>{window.scrollTo(x, y);};
            }

            function enableScrolling()
            {
                window.onscroll=null;
            }

            function showMenu()
            {
                document.getElementById('fullScreen').style.display = 'flex';
                disableScrolling();
            }

            function hideMenu()
            {
                document.getElementById('fullScreen').style.display = 'none';
                enableScrolling();
            }

            return {showMenu, hideMenu};
        }
    }
</script>
<style lang="scss" scoped>
@import '../style/style.scss';
    #baner
    {
        width: 100vw;
        height: 100px;
        max-height: 100px;

        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        background-color: $main-color;
        user-select: none;
        z-index: 1000;

        //border-bottom: 5px solid $gold-color;

        #menuOnly
        {
            display: flex;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            height: 2rem;
            background-size: contain;
            background-repeat: no-repeat;
        }

        #fullScreen
        {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: $main-color;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            z-index: 1000;

            a
            {
                font-size: 2rem;
                color: white;
                text-decoration: none;
                transition: 0.3s ease-in-out;
                text-align: center;

                &:hover
                {
                    color: $gold-color;
                }
            }

            #closeMenu
            {
                cursor: pointer;
                transition: 0.3s ease-in-out;
                height: 2rem;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        #links
        {
            display: none;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 40%;

            a
            {
                cursor: pointer;
                transition: 0.3s ease-in-out;
                white-space: nowrap;
                color: white;
                text-decoration: none;
                font-size: 1.1rem;

                &:hover
                {
                    color: $gold-color;
                }
            }

            #menu
            {
                //display: none;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                height: 2rem;
                background-size: contain;
                background-repeat: no-repeat;
            }

        }

        & #logo
        {
            height: 90%;
            background-size: contain;
            background-repeat: no-repeat;

            img
            {
                height: 100%;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #menuOnly
        {
            display: none !important;
        }

        #links
        {
            display: flex !important;
        }
    }
</style>