// src/components/PortfolioAd.vue

<template>
    <div class="page-card">
      <div class="page-card__header">
        <h2>{{ title }}</h2>
        <span class="page-card__number">{{ number }}</span>
      </div>
      <div class="page-card__description">
        <p><b>Branża: </b>{{ profession }}</p><a></a>
        <p><b>Koszt przed współpracą: </b>{{ start }}</p>
        <p><b>Koszt końcowy: </b>{{ end }}</p>
        <p v-html="description"></p>
      </div>
      <div class="page-card__stats">
        <img :src="imageStats" alt="Statistics Image" />
      </div>
      <div class="page-card__image">
        <img :src="imageAd" alt="Ad Image" />
      </div>
    </div>
  </template>

  <script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PortfolioAd',
    props: {
      title: {
        type: String,
        required: true
      },
      profession: {
        type: String,
        required: true
      },
      start: {
        type: String,
        required: true
      },
      end: {
        type: String,
        required: true
      },
      imageAd: {
        type: String,
        required: true
      },
      imageStats: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      number: {
        type: Number,
        required: true
      }
    }
  });
  </script>


<style scoped>
.page-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding-bottom: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  margin: 16px;
  background-color: #fff;
  display: block !important;
}

.page-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: black;
  border-bottom: 1px solid #eee;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.page-card__header h2 {
  margin: 0;
  font-size: 1.2em;
}

.page-card__number {
  font-size: 1.2em;
  color: #777;
}

.page-card__image img {
  width: 100%;
  height: auto;
  display: block;
}

.page-card__stats img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 8px;
}

.page-card__description {
  padding: 16px;
}

.page-card__description p {
  margin: 0;
  color: #333;
}
</style>
