<template>
	<div id="main">
	<h1>Zobacz, co zrobiliśmy dla innych <a class="gold">firm</a>!</h1>
	<h2>Reklamy w <a class="gold">Social Mediach</a></h2>
	<div id="adPortfolio">
		<div id="adPortfolio1">
		<PortfolioAd
			v-for="(ad, index) in filteredAds.filteredAds1"
			:key="index"
			:title="ad.title"
			:profession="ad.profession"
			:start="ad.startAmount"
			:end="ad.endAmount"
			:imageAd="ad.imageAd"
			:imageStats="ad.imageStats"
			:description="ad.description"
			:number="index * 3 + 1"
		/>
		</div>
		<div id="adPortfolio2">
		<PortfolioAd
			v-for="(ad, index) in filteredAds.filteredAds2"
			:key="index"
			::title="ad.title"
			:profession="ad.profession"
			:start="ad.startAmount"
			:end="ad.endAmount"
			:imageAd="ad.imageAd"
			:imageStats="ad.imageStats"
			:description="ad.description"
			:number="index * 3 + 2"
		/>
		</div>
		<div id="adPortfolio3">
		<PortfolioAd
			v-for="(ad, index) in filteredAds.filteredAds3"
			:key="index"
			:title="ad.title"
			:profession="ad.profession"
			:start="ad.startAmount"
			:end="ad.endAmount"
			:imageAd="ad.imageAd"
			:imageStats="ad.imageStats"
			:description="ad.description"
			:number="index * 3 + 3"
		/>
		</div>
	</div>
	<h2>Nasze <a class="gold">Strony Internetowe</a></h2>
	<div id="pagePortfolio">
		<div id="pagePortfolio1">
		<PortfolioPageComponent
			v-for="(page, index) in filteredPages.filteredPages1"
			:key="index"
			:title="page.title"
			:profession="page.profession"
			:imagePage="page.imagePage"
			:link="page.link"
			:description="page.description"
			:number="index * 3 + 1"
		/>
		</div>
		<div id="pagePortfolio2">
		<PortfolioPageComponent
			v-for="(page, index) in filteredPages.filteredPages2"
			:key="index"
			:title="page.title"
			:profession="page.profession"
			:imagePage="page.imagePage"
			:link="page.link"
			:description="page.description"
			:number="index * 3 + 2"
		/>
		</div>
		<div id="pagePortfolio3">
		<PortfolioPageComponent
			v-for="(page, index) in filteredPages.filteredPages3"
			:key="index"
			:title="page.title"
			:profession="page.profession"
			:imagePage="page.imagePage"
			:link="page.link"
			:description="page.description"
			:number="index * 3 + 3"
		/>
		</div>
	</div>
	</div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import PortfolioAd from './adComponent.vue'; // Adjust the path as necessary
import PortfolioPageComponent from './pageComponent.vue'; // Adjust the path as necessary
import { AdData, PageData } from '@/data/adClass.js'; // Adjust the path as necessary

export default defineComponent({
	name: 'PortfolioPage',
	components: {
	PortfolioAd,
	PortfolioPageComponent,
	},
	setup() {
	document.title = 'Portfolio';

	const ads = [
		new AdData('Aga Tarajko-Bąk', 'Fotografka Wizerunkowa', 'Pierwsza reklama', '0,89 PLN za kliknięcie', '<b>Opis: </b>Stworzyliśmy reklamy, które miały bardzo dobrą cenę za kliknięcie. <br><br>Dzięki temu duża ilość osób mogła zobaczyć ofertę Agi, a następnie umówić się na sesję zdjęciową.', require('@/components/portfolio/adPictures/copyAdAgatarajkobak.png'), require('@/components/portfolio/adPictures/resultsAdAgatarajkobak.png')),
		new AdData('Raj-Spa', 'Tarocistka', 'Pierwsza reklama', '0,96 PLN za kliknięcie', '<b>Opis: </b>Reklamy, które stworzylśmy, bardzo dobrze się klikały i przy kwocie 20 PLN dziennie zwiększyły ruch na stronie o średnio 21 nowych osób dziennie. <br><br>Teraz wystarczy to wykorzystać i z pomocą dobrej strony internetowej zamienić przeglądających w klientów.', require('@/components/portfolio/adPictures/copyAdRajspa.png'), require('@/components/portfolio/adPictures/resultsAdRajspa.png')),
	];

	const pages = [
		new PageData('Pay24hrs', 'Finanse', 'https://pay24hrs.com/', '<b>Opis: </b>Jeden z naszych większych projektów stron internetowych. To jest nie tylko dobry landing page. Po zarejestrowaniu się uzyskujesz dostęp do jednego z najlepszych narzędzi do faktur, jakie istnieje. <br><br>Narzędzie ma tylko konieczne dla normalnej osoby funkcje, a pozwala na przykład wysłać 1234 faktury do swoich kontraktorów jednym kliknięciem. <br><br>Z ciekawych funkcji jest jeszcze łatwy import i edycja faktur, dostęp do całej bazy NIP, łączenie setek faktur z wybranego okresu w jedną (wtedy mniej płacisz księgowym).', require('@/components/portfolio/pagePictures/pagePay24hrs.png'), ),
		new PageData('Gigakorki', 'Edukacja', 'https://gigakorki.pl/', '<b>Opis: </b>Nasz największy projekt do tej pory. Portal internetowy do wrzucania ogłoszeń z korepetycjami. <br><br>Portal ma ogromną ilość funkcji, niedługo ma zostać uruchomiony.', require('@/components/portfolio/pagePictures/pageGigakorki.png')),
	];

	const filteredAds = computed(() => {
		return {
		filteredAds1: ads.filter((ad, index) => index % 3 === 0),
		filteredAds2: ads.filter((ad, index) => (index + 1) % 3 === 0),
		filteredAds3: ads.filter((ad, index) => (index + 2) % 3 === 0),
		};
	});

	const filteredPages = computed(() => {
		return {
		filteredPages1: pages.filter((page, index) => index % 3 === 0),
		filteredPages2: pages.filter((page, index) => (index + 1) % 3 === 0),
		filteredPages3: pages.filter((page, index) => (index + 2) % 3 === 0),
		};
	});

	return { ads, filteredAds, pages, filteredPages };
	},
});
</script>


<style lang="scss" scoped>
@import '@/style/style.scss'; // Use alias for src directory

#main {
    margin-bottom: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        width: 100%;
        text-align: center;
        font-size: 3rem;
        font-weight: 300;
    }

    h2 {
        width: 100%;
        text-align: center;
        font-size: 2rem;
        font-weight: 300;
    }

    h3 {
        width: 80%;
        text-align: left;
        font-size: 2rem;
        font-weight: 300;
    }

    .gold {
        color: $gold-color;
    }
}

#adPortfolio, #pagePortfolio {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

#pagePortfolio1, #pagePortfolio2, #pagePortfolio3,
#adPortfolio1, #adPortfolio2, #adPortfolio3 {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.ad-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
}

@media only screen and (max-width: 600px) {
    #adPortfolio, #pagePortfolio {
        flex-direction: column;
        align-items: center;
    }

    #pagePortfolio1, #pagePortfolio2, #pagePortfolio3,
    #adPortfolio1, #adPortfolio2, #adPortfolio3 {
        width: 100%;
        flex-direction: column;
    }
}
</style>
