<template>
    <div id="main">
      <h1>Blog</h1>
      <div class="articles">
        <h2>Dowiedz się, w jaki sposób stworzyć skuteczny marketing krok po kroku.</h2>
        <router-link
          v-for="article in visibleArticles"
          :key="article.id"
          :to="{ name: 'articlePage', params: { id: article.id, slug: generateSlug(article.title) } }"
          class="article"
        >
          <div class="article-image">
            <img :src="getImageUrl(article.other_source_image_url)" alt="article image" @error="onImageError">
          </div>
          <div class="article-right">
            <div class="article-author">
              <img class="article-author-photo" src="./blogAssets/Rafal.png" alt="author photo">
              <p class="article-author-name">{{ article.author }}</p>
              <p class="article-author-published">{{ formatDate(article.publication_date) }}</p>
              <img class="article-author-share" @click.stop.prevent="shareArticle(article)" src="./blogAssets/share.png" alt="share icon">
            </div>
            <div class="article-title">
              {{ article.title }}
            </div>
            <div class="article-about">
              {{ article.description }}
            </div>
            <div class="article-info">
              <p class="article-info-views">{{ article.views_number }} wyświetleń</p>
              <p class="article-info-likes">{{ article.likes_number }} polubień</p>
            </div>
          </div>
        </router-link>
        <button v-if="moreArticlesAvailable" @click="loadMoreArticles" class="button">
          Załaduj więcej
        </button>
      </div>
    </div>
  </template>

  <script>
  import { ref, computed, onMounted } from 'vue';
  import axios from 'axios';

  export default {
    setup() {
      document.title = "Blog RMD Solutions";
      const articles = ref([]);
      const articlesToShow = ref(10);

      const visibleArticles = computed(() => {
        return articles.value.slice(0, articlesToShow.value);
      });

      const moreArticlesAvailable = computed(() => {
        return articlesToShow.value < articles.value.length;
      });

      const loadMoreArticles = () => {
        articlesToShow.value += 10;
      };

      const generateSlug = (title) => {
        const polishToEnglishMap = {
          'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
          'Ą': 'a', 'Ć': 'c', 'Ę': 'e', 'Ł': 'l', 'Ń': 'n', 'Ó': 'o', 'Ś': 's', 'Ź': 'z', 'Ż': 'z'
        };

        return title.toLowerCase()
                    .split('')
                    .map(char => polishToEnglishMap[char] || char)
                    .join('')
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
      };

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('pl-PL', options);
      };

      const getImageUrl = (url) => {
        return url ? url : 'https://via.placeholder.com/800x400';
      };

      const onImageError = (event) => {
        event.target.src = 'https://via.placeholder.com/800x400';
      };

      const shareArticle = (article) => {
        const articleUrl = `${window.location.origin}/blog/${article.id}/${generateSlug(article.title)}`;
        const title = article.title;

        if (navigator.share) {
          navigator.share({
            title: title,
            url: articleUrl,
          }).then(() => {
            console.log('Article shared successfully');
          }).catch((error) => {
            console.error('Error sharing the article:', error);
          });
        } else if (navigator.clipboard) {
          navigator.clipboard.writeText(articleUrl).then(() => {
            alert('Link do artykułu został skopiowany do schowka');
          }).catch((error) => {
            console.error('Error copying the link:', error);
          });
        } else {
          prompt('Skopiuj ten link do artykułu:', articleUrl);
        }
      };

      const fetchArticles = async () => {
        try {
          const response = await axios.get('https://rmdsolutions.eu:8002/api/blog_posts');
          articles.value = response.data.reverse(); // Odwrócenie kolejności, aby najnowsze były na górze
        } catch (error) {
          console.error('Error fetching articles:', error);
        }
      };

      onMounted(() => {
        fetchArticles();
      });

      return {
        articles,
        visibleArticles,
        moreArticlesAvailable,
        loadMoreArticles,
        generateSlug,
        formatDate,
        getImageUrl,
        onImageError,
        shareArticle
      };
    }
  }
  </script>

<style lang="scss" scoped>
    #main {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $main-color;
        z-index: 1000;

        .button {
            height: 3rem;
            font-size: 2rem;
            padding: 10%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5%;
            white-space: nowrap;
            transition: 0.2s ease-in-out;
            border: 1px solid $gold-color;
            border-radius: 75px;
            text-decoration: none;
            color: $font-color;
            background-color: $gold-color;
            font-weight: 200;
            cursor: pointer;

            &:hover {
                background-color: $font-color;
                border: 1px solid $font-color;
                color: $gold-color;
            }
        }

        h1 {
            font-size: 3em;
        }

        img {
            user-select: none;
        }

        .articles {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .article {
            width: 80%;
            aspect-ratio: 2 / 1;
            display: flex;
            margin-top: 5%;
            border: 1px solid white;
            text-decoration: none;
            color: white;

            .article-right {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2%;
            }

            .article-image {
                width: 50%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .article-title {
                font-size: 2em;
            }

            .article-about {
                font-size: 1.5em;
            }

            .article-author {
                display: grid;
                grid-template-columns: 50px 1fr 50px;
                grid-template-rows: 25px 25px;
                grid-template-areas: "photo name share"
                                    "photo published share";

                p {
                    margin: 0;
                }

                .article-author-photo {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    grid-area: photo;
                }

                .article-author-name {
                    font-size: 1em;
                    margin-left: 5%;
                    grid-area: name;
                }

                .article-author-published {
                    font-size: 1em;
                    margin-left: 5%;
                    grid-area: published;
                }

                .article-author-share {
                    width: 50px;
                    height: 50px;
                    margin-left: 5%;
                    grid-area: share;
                }
            }

            .article-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid white;

                .article-info-views {
                    font-size: 1.5em;
                    margin-right: 5%;
                }

                .article-info-likes {
                    font-size: 1.5em;
                    margin-right: 5%;

                    img {
                        width: 1em;
                        height: 100%;
                    }
                }
            }
        }

        /* Mobile adjustments */
        @media (max-width: 768px) {
            h1 {
                font-size: 2em;
            }

            .article {
                width: 100%;
                flex-direction: column;
                border: none;
                aspect-ratio: auto;

                .article-right,
                .article-image {
                    width: 100%;
                }

                .article-title {
                    font-size: 1.5em;
                    margin-bottom: 1rem;
                }

                .article-about {
                    font-size: 1.2em;
                    margin-bottom: 1rem;
                }

                .article-info {
                    flex-direction: row;
                    justify-content: space-between;

                    p{
                        font-size: 1em !important;
                    }
                }

                .article-author {
                    margin: 1rem 0;
                }

                .button {
                    font-size: 1.5rem;
                    padding: 5%;
                }
            }
        }
    }
</style>
