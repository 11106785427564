<template>
	<banerPage/>
	<mainView/>
	<footerPage/>
</template>
<script>
import banerPage from './components/banerPage.vue'
import footerPage from './components/footerPage.vue'
import mainView from './components/mainView.vue'
export default {
    name: 'App',
    components:
    {
        mainView,
        banerPage,
        footerPage
    }

}
</script>
<style lang="scss">
@import './style/style.scss';
	*
	{
		box-sizing: border-box;
		font-family: foncik;
	}
	#app {
		font-family: foncik;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $font-color;
		box-sizing: border-box;
	}
	body
	{
		margin: 0;
		background-color: $main-color;
	}
</style>
