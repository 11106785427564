<template>
    <div id="main">
        <div id="part5" class="part">
            <h1><a class="gold">Dzięki</a>!</h1>
            <p>
                Twój darmowy poradnik jest już w drodze i powinien dotrzeć do Twojej skrzynki w ciągu pięciu minut. Sprawdź folder SPAM, jeśli nie dotarł do Ciebie w tym czasie!
            </p>
            <h2>
                Czy chcesz, żebyśmy pomogli Ci z <a class="gold">marketingiem</a>?
            </h2>
            <p>
                Czy chcesz wiedzieć, co możemy zrobić dla Twojej firmy? Wypełnij formularz poniżej i umówmy się na krótkie spotkanie - całkowicie za darmo! Nie martw się, nie będziemy męczyć Cię zobowiązaniami ani irytującą sprzedażą. Będziemy szanować Twój czas.
            </p>
        </div>
        <div id="part7" class="part">
            <div id="form">
                <input type="text" class="form50" placeholder="Imię" v-model="name"/>
                <input type="email" class="form50" placeholder="E-Mail" v-model="email"/>
                <input type="text" class="form50" placeholder="Firma" v-model="company"/>
                <input type="text" class="form50" placeholder="Telefon"  v-model="phone"/>
                <input type="text" class="form100" placeholder="Jakie jest Twoje najważniejsze pytanie?" v-model="question"/>
                <textarea placeholder="Wiadomość"  v-model="message"></textarea>
                <a class="button" @click="sendInfo">WYŚLIJ</a>
            </div>
            <a ref="warningRef">{{ warningMessage }}</a>
        </div>
    </div>
</template>
<script>
import { inject, ref, reactive, toRefs } from 'vue'
import axios from 'axios';

export default {
    setup() {
        document.title = "Dziękujemy"
        const endpoint = inject("g_endpoint");

        const formData = reactive({
            name: "",
            email: "",
            company: "",
            phone: "",
            question: "",
            message: ""
        });

        const warningMessage = ref("");

        const warningRef = ref(null);

        const validateField = (condition, message) => {
            if (!condition) {
                warningMessage.value = message;
                if (warningRef.value) {
                    warningRef.value.style.visibility = "visible";
                }
                return false;
            }
            return true;
        };


        const sendInfo = async () => {
            if (!validateField(formData.name !== '', 'Podaj imię.')) return;
            if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), 'Podaj poprawny adres email.')) return;
            if (!validateField(formData.company !== '', 'Podaj nazwę firmy.')) return;
            if (!validateField(/^\d{9}$/.test(formData.phone), 'Numer telefonu powinien składać się z 9 cyfr.')) return;
            if (!validateField(formData.question !== '' || formData.message !== '', 'Pytanie i wiadomość nie mogą być puste.')) return;

            try {
                let dataToSend = {
                    name: formData.name,
                    email: formData.email,
                    company: formData.company,
                    phone_number: formData.phone,
                    main_question: formData.question,
                    message: formData.message
                }
                const jsonFormData = JSON.stringify(dataToSend);

                const response = await axios
                .post(`${endpoint}messages/`, jsonFormData, {
                withCredentials: true,
                headers: {'Content-Type': "application/json"}})

                Object.keys(formData).forEach(key => formData[key] = '');
                warningMessage.value = "Wiadomość wysłana poprawnie";
            } catch (error) {
                console.error("Błąd podczas wysyłania danych:", error);
                warningMessage.value = "Wiadomość nie została wysłana";
            }
        };


        const scrollToForm = () => {
            const formElement = document.getElementById("form");
            if (formElement) {
                formElement.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return { ...toRefs(formData), warningMessage, sendInfo, scrollToForm, warningRef }
    }
}
</script>
<style lang="scss" scoped>
@import 'src/style/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        .part
        {
            width: 100%;
            padding-top: 5%;
            padding-bottom: 5%;
        }

        a.gold
        {
            color: $gold-color;
        }

        #part5
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1
            {
                font-size: 5rem;
                width: 90%;
                text-align: center;
                margin-bottom: 0;
            }

            h2
            {
                font-size: 3rem;
                line-height: 3rem;
                width: 90%;
                text-align: center;
            }

            p
            {
                font-size: 1.2rem;
                width: 90%;
            }
        }

        #part5 h1
        {
            font-weight: 300 !important;
        }

        #part6
        {
            display: flex;
            justify-content: center;

            h2
            {
                font-size: 1.5rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }

            h1
            {
                font-size: 2rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }
        }

        #part7
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            h3
            {
                font-size: 1.2rem;
                width: 90%;
                line-height: 2rem;
            }

            #warning
            {
                margin-top: 1%;
                visibility: hidden;
            }

            #form
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 90%;


                input
                {
                    padding: 1%;
                    padding-left: 2%;
                    margin: 0;
                }

                textarea
                {
                    padding: 1%;
                    padding-left: 2%;
                    margin: 0;
                    width: 100%;
                    resize: none;
                    height: 10rem;
                    margin-top: 1%;
                }

                .form50
                {
                    width: 100%;
                    margin-top: 1%;
                    margin-bottom: 1%;
                }

                .form100
                {
                    width: 100%;
                    margin-top: 1%;
                    margin-bottom: 1%;
                }

                a
                {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        input, textarea
        {
            outline: none;
            background-color: $main-color;
            border: none;
            border-bottom: 1px solid white;
            color: white;
            font-size: 1rem;
        }

        .button
        {
            height: 3rem;
            font-size: 2rem;
            padding: 10%;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5%;

            white-space: nowrap;
            transition: 0.2s ease-in-out;
            border: 1px solid $gold-color;
            border-radius: 75px;
            text-decoration: none;
            color: $font-color;
            background-color: $gold-color;
            font-weight: 200;
            cursor: pointer;

            &:hover
            {
                background-color: $font-color;
                border: 1px solid $font-color;
                color: $gold-color;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #main
        {
            .part
            {
                padding-top: 2%;
                padding-bottom: 2%;
            }

            #part6
            {
                h2
                {
                    font-size: 3rem;
                    line-height: 4rem;
                    width: 75%;
                }

                h1
                {
                    font-size: 4rem;
                    line-height: 5rem;
                }
            }

            #part7
            {
                h3
                {
                    width: 75%;
                }

                #form
                {
                    width: 75%;

                    .form50
                    {
                        width: 47%;
                    }
                }
            }

            input, textarea
            {
                font-size: 1.5rem;
            }

            .button
            {
                font-size: 2.5rem;
                padding: 3%;

                margin-top: 2%;
            }
        }
    }
</style>