<template>
    <div id="main">
        <div id="part5" class="part">
            <h1>O nas - kto stoi za RMD Solutions?</h1>
        </div>
        <div id="part6" class="part">
            <h2>Rafał, Marek, Mateusz, Stanisław</h2>
            <p></p>
        </div>
    </div>
</template>
<script>

export default {
    setup() {
        document.title = "O RMD Solutions"
    }
}
</script>
<style lang="scss" scoped>
@import '../style/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        .part
        {
            width: 100%;
            padding-top: 5%;
            padding-bottom: 5%;
        }

        a.gold
        {
            color: $gold-color;
        }

        #part5 h1
        {
            font-weight: 300 !important;
        }

        #part6, #part5
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2
            {
                font-size: 1.5rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }

            h1
            {
                font-size: 2rem;
                line-height: 2.5rem;
                width: 90%;
                text-align: center;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #main
        {
            .part
            {
                padding-top: 2%;
                padding-bottom: 2%;
            }

            #part6, #part5
            {
                h2
                {
                    font-size: 3rem;
                    line-height: 4rem;
                    width: 75%;
                }

                h1
                {
                    font-size: 4rem;
                    line-height: 5rem;
                }
            }
        }
    }
</style>