<template>
    <div id="container-main">
        <div id="main-1">
            <RouterView/>
        </div>
    </div>
</template>
<script>
export default
{

}
</script>
<style lang="scss" scoped>
    #container-main
    {
        //user-select: none;
        display: flex;
        justify-content: center;

        #main-1
        {
            //padding-top: 100px;
            width: 90vw;
        }
    }
</style>