<template>
    <div id="main">
        <h2>Wyniki przedsiębiorców, którzy podjęli najlepszą decyzję</h2>
        <a>
            Dopiero gromadzimy imponującą listę opinii, którymi będziemy mogli się pochwalić.<br>
            Każda opinia będzie potwierdzona zrzutem ekranu z zyskami naszych klientów.<br>
            Niedługo każdy będzie mógł się z nią zapoznać.
        </a>
    </div>
</template>
<script>
    export default
    {
        setup()
        {
            document.title = "Opinie"
        }
    }
</script>
<style lang="scss" scoped>
@import 'src/style/style.scss';
    #main
    {
        margin-bottom: 20%;

        width: 100%;
        //margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2
        {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            font-weight: 300;
        }

        h3
        {
            width: 80%;
            text-align: left;
            font-size: 2rem;
            font-weight: 300;
        }

        a
        {
            width: 80%;
            font-size: 1.2rem;
            line-height: 2rem;
            text-align: left;
            font-weight: 200;
        }
    }
</style>